import { useRef, useEffect, useLayoutEffect } from 'react';

// ----------------------------------------------------------------------

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useEventListener(eventNames, handler, element, options) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current || window;

    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Ensure eventNames is an array
    const events = Array.isArray(eventNames) ? eventNames : [eventNames];

    // Create event listener that calls handler function stored in ref
    const eventListener = event => savedHandler.current(event);

    // Add event listeners for all events
    events.forEach(eventName => {
      targetElement.addEventListener(eventName, eventListener, options);
    });

    // Remove event listeners on cleanup
    return () => {
      events.forEach(eventName => {
        targetElement.removeEventListener(eventName, eventListener);
      });
    };
  }, [eventNames, element, options]);
}
