import React from 'react';
import { Box, styled } from '@mui/material';

export const LoadingDots = () => {
  return (
    <StyledBox>
      <Dot delay='0s' />
      <Dot delay='0.3s' />
      <Dot delay='0.6s' />
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 100,
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(2),

  '@keyframes flashing': {
    '0%': { opacity: 1, transform: 'scale(0.95)' },
    '50%': { opacity: 0.1, transform: 'scale(1)' },
    '100%': { opacity: 1, transform: 'scale(1.05)' }
  }
}));

const Dot = styled(Box)(({ theme, delay }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[500],
  animation: `flashing 1s infinite ${delay}`,
  marginLeft: theme.spacing(1)
}));
