import { LOGS_PAGE_SIZE } from '@rc/admin/constants';
import { useCallback, useEffect, useState } from 'react';

/**
 * Constants and helpers
 */

const DEFAULT_OFFSET = 10;
const OFFSETS = [
  { label: '1 day', value: 86400 },
  { label: '1 hour', value: 3600 },
  { label: 'Live', value: DEFAULT_OFFSET }
];

/**
 * Custom hook to manage logs with pagination and live mode.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {(params: SubscribeParams) => void} params.subscribe - Function to subscribe to logs.
 * @param {(params: MoreParams) => void} params.more - Function to fetch more logs.
 * @param {(params: StopParams) => void} params.stop - Function to stop fetching logs.
 * @returns {Object} The state and functions to manage logs.
 * @returns {Array} returns.logs - The list of logs.
 * @returns {boolean} returns.isPaging - Indicates if logs are being paged.
 * @returns {boolean} returns.isLoading - Indicates if logs are being loaded.
 * @returns {boolean} returns.isRunning - Indicates if logs fetching is running.
 * @returns {boolean} returns.canShowMore - Indicates if more logs can be shown.
 * @returns {number} returns.offset - The current offset for logs.
 * @returns {Array} returns.offsets - The list of possible offsets.
 * @returns {boolean} returns.isLiveMode - Indicates if live mode is active.
 * @returns {Function} returns.start - Function to start fetching logs.
 * @returns {Function} returns.stop - Function to stop fetching logs.
 * @returns {Function} returns.more - Function to fetch more logs.
 * @returns {Function} returns.changeOffset - Function to change the offset.
 */
export function useLogs ({
  subscribe: subscribeImpl,
  more: moreImpl,
  stop: stopImpl
}) {
  const [logs, setLogs] = useState([]);
  const [isPaging, setIsPaging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const isLiveMode = offset === DEFAULT_OFFSET;

  const start = useCallback(
    async (record, filters) => {
      await subscribeImpl({
        record,
        filters,
        offset,
        setLogs,
        setIsPaging,
        setIsLoading,
        setIsRunning
      });
    },
    [subscribeImpl, offset]
  );

  const more = useCallback(
    async (record, filters) => {
      if (isLiveMode) return;

      await moreImpl({
        record,
        filters,
        setLogs,
        setIsPaging,
        setIsLoading,
        setIsRunning
      });
    },
    [moreImpl, isLiveMode]
  );

  const stop = useCallback(() => {
    stopImpl({
      isRunning,
      setLogs,
      setIsPaging,
      setIsLoading,
      setIsRunning
    });
    setIsRunning(false);
  }, [stopImpl]);

  const changeOffset = useCallback(
    value => {
      setOffset(value);
      stop();
    },
    [stop]
  );

  // canShowMore: only when running, not already paging, and if a full page was received.
  const canShowMore =
    isRunning &&
    !isPaging &&
    !isLoading &&
    logs.length !== 0 &&
    logs.length % LOGS_PAGE_SIZE === 0;

  useEffect(() => {
    return () => stop();
  }, [stop]);

  return {
    logs,
    isPaging,
    isLoading,
    isRunning,
    canShowMore,
    offset,
    offsets: OFFSETS,
    isLiveMode,
    start,
    stop,
    more,
    changeOffset
  };
}

/**
 * @typedef {Object} BaseParams
 * @property {Function} setLogs - Function to set logs.
 * @property {Function} setIsPaging - Function to set paging state.
 * @property {Function} setIsLoading - Function to set loading state.
 * @property {Function} setIsRunning - Function to set running state.
 */

/**
 * @typedef {Object} SubscribeParams
 * @extends BaseParams
 * @property {Object} record - The record to subscribe to.
 * @property {Object} filters - The filters to apply.
 * @property {number} offset - The offset for logs.
 */

/**
 * @typedef {Object} MoreParams
 * @extends BaseParams
 * @property {Object} record - The record to fetch logs from.
 * @property {Object} filters - The filters to apply.
 */

/**
 * @typedef {Object} StopParams
 * @extends BaseParams
 * @property {boolean} isRunning - Indicates if logs fetching is running.
 */
