import React, { useCallback, useEffect, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Button,
  useMediaQuery
} from '@mui/material';
import { PlayArrow as PlayIcon, Stop as StopIcon } from '@mui/icons-material';
import { LogsDisplay } from '../components';
import { useLocation } from 'react-router';
import { useMercureLogs, useMockLogs } from '@rc/admin/hooks';

const useLogs = process.env.MOCK_LOGS ? useMockLogs : useMercureLogs;

const FILTERS = [
  { component: 'application', color: '#6E44FF' },
  { component: 'database', color: '#33B679' },
  { component: 'message-queue', color: '#FF6F61' },
  { component: 'search-engine', color: '#FFA737' },
  { component: 'redis', color: '#C13584' },
  { component: 'http-cache', color: '#008080' },
  { component: 'build', color: '#7D3F98' },
  { component: 'export-db', color: '#63C5DA' },
  { component: 'export-media', color: '#B5651D' },
  { component: 'import-db', color: '#6FA96F' },
  { component: 'import-media', color: '#DA4167' }
];

const DEFAULT_FILTERS = [];

/**
 *
 * @param {object} props
 * @param {(location: import('react-router').Location) => boolean} props.shouldRender
 * @returns
 */
export const Logs = props => {
  const { shouldRender: getShouldRender } = props;
  const t = useTranslate();
  const record = useRecordContext();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const shouldRender = getShouldRender(useLocation());

  const {
    logs,
    isLoading,
    isRunning,
    isPaging,
    canShowMore,
    isLiveMode,
    offsets,
    offset,
    start,
    stop,
    more,
    changeOffset
  } = useLogs({
    record,
    filters,
    shouldRender
  });

  useEffect(() => {
    if (!shouldRender) {
      stop();
    }
  }, [shouldRender, stop]);

  // useEffect(() => {
  //   if (isRunning && shouldRender && canShowMore) {
  //     more(record, filters);
  //   }
  // }, [canShowMore, isLiveMode, filters, isRunning, more, record, shouldRender]);

  const onOffsetChanged = useCallback(
    value => {
      changeOffset(value);
    },
    [stop]
  );

  const handleLoadMore = useCallback(() => {
    more(record, filters);
  }, [filters, more, record]);

  if (!shouldRender) {
    return null;
  }

  return (
    <StyledCard sx={{ width: '100%' }}>
      <CardContent>
        <Stack className={classes.content}>
          <Stack className={classes.actions}>
            <FormControl>
              <InputLabel id='logs-filter-select-label' size='small'>
                {t(`resources.environments.sections.logs.filters`)}
              </InputLabel>
              <Select
                label={t(`resources.environments.sections.logs.filters`)}
                labelId='logs-filter-select-label'
                size='small'
                id='logs-filter-select'
                multiple
                value={filters}
                onChange={event => {
                  const { value } = event.target;
                  setFilters(
                    typeof value === 'string' ? value.split(',') : value
                  );
                  stop();
                }}
                sx={{ minWidth: 240 }}
              >
                {FILTERS.map(filter => (
                  <MenuItem key={filter.component} value={filter.component}>
                    {t(
                      `resources.environments.sections.logs.${filter.component.replace(
                        '-',
                        '_'
                      )}`
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack flexDirection='row' gap={1}>
              <ButtonSelect
                options={offsets}
                value={offset}
                onChange={onOffsetChanged}
                getTitle={label => `Change offset to ${label}`}
              />
              <Button
                variant='contained'
                color={!isRunning ? 'primary' : 'error'}
                onClick={() =>
                  isRunning ? stop() : start(record, filters, offset)
                }
                isActive={isLoading}
                title={t(
                  isRunning
                    ? 'resources.environments.sections.logs.stop_streaming'
                    : 'resources.environments.sections.logs.start_streaming'
                )}
              >
                {isSmall ? (
                  isRunning ? (
                    <StopIcon />
                  ) : (
                    <PlayIcon />
                  )
                ) : (
                  t(
                    isRunning
                      ? 'resources.environments.sections.logs.stop_streaming'
                      : 'resources.environments.sections.logs.start_streaming'
                  )
                )}
              </Button>
            </Stack>
          </Stack>
          <LogsDisplay
            logs={logs}
            isLoading={isLoading}
            isRunning={isRunning}
            isPaging={isPaging}
            canShowMore={canShowMore}
            isLiveMode={isLiveMode}
            filters={FILTERS}
            onLoadMore={handleLoadMore}
          />
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

/**
 *
 * @param {import('react-admin').ButtonProps & { options: [{label: string, value: number}], value: number, getTitle: (label: string) => string}} props
 * @returns
 */
const ButtonSelect = props => {
  const { value, options, onChange, getTitle, ...rest } = props;

  return (
    <Stack flexDirection='row'>
      {options.map(({ label, value: optionValue }) => (
        <Button
          key={label}
          variant='text'
          onClick={() => onChange(optionValue)}
          disabled={value === optionValue}
          title={getTitle(label)}
          sx={theme => ({
            ':disabled': {
              color: 'currentcolor',
              opacity: 0.5
            }
          })}
          {...rest}
        >
          {label}
        </Button>
      ))}
    </Stack>
  );
};

const PREFIX = 'Logs';

const classes = {
  actions: `${PREFIX}-actions`,
  content: `${PREFIX}-content`,
  bottomToolbar: `${PREFIX}-bottom-toolbar`,
  moreButton: `${PREFIX}-more-button`
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.content}`]: {
    width: '100%',
    rowGap: theme.spacing(2),
    alignItems: 'flex-start'
  },

  [`& .${classes.actions}`]: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },

  [`& .${classes.bottomToolbar}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    minHeight: theme.spacing(1.5),
    background:
      theme.palette.mode === 'light' ? '#F9F9F9' : `rgba(145, 158, 171, 0.03)`,
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,

    [`& .${classes.moreButton}`]: {
      margin: `${theme.spacing(1)} 0 ${theme.spacing(2)}`
    }
  }
}));

Logs.defaultProps = {
  shouldRender: () => true
};
