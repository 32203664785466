/**
 * Appearance
 */
export const BRAND_NAME = 'Reward Cloud';
export const DEFAULT_PROJECT_COLOR = '#5D35B1';

/**
 * Enable/disable features
 */
export const ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS = false;

/**
 * Logs pagination page size
 */
export const LOGS_PAGE_SIZE = process.env.LOGS_PAGE_SIZE || 500;

/**
 * Caching
 */

// 5 minutes cache duration
export const CACHE_DATA_DURATION = 5 * 60 * 1000;
export const ORG_LOCAL_STORAGE_KEY = 'Rc.SelectedOrg';
export const REDIRECT_TO_TEAM_LOCAL_STORAGE_KEY = 'Rc.RedirectToTeam';
export const FEATURE_TOUR_STORAGE_KEY_PREFIX = 'Rc.Tour';

/**
 * Roles
 */

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

/**
 * Exported from the backend code
 */

export const GRECAPTCHA_SITEKEY = window.__GRecaptchaSiteKey__;
export const GRECAPTCHA_ENABLED = window.__GRecaptchaEnable__ === 'true';

/**
 * @type {import("./types/state.types").StateEnum}
 */
export const STATE_IDS = new Proxy(window.__RCStateEnum__, {
  get(obj, prop) {
    return obj[prop];
  },
});
export const DATA_TRANSFER_TYPE_IDS = new Proxy(
  window.__RCDataTransferTypeEnum__,
  {
    get(obj, prop) {
      return obj[prop];
    },
  }
);
export const CRYPTO_KEY_TYPE_IDS = new Proxy(window.__RCCryptoKeyTypeEnum__, {
  get(obj, prop) {
    return obj[prop];
  },
});
export const ROLE_GROUPS = new Proxy(window.__RCRoleGroupsEnum__, {
  get(obj, prop) {
    return obj[prop];
  },
});
export const MAGE_RUN_TYPES = ['store', 'website'];
/**
 * @type {import("./types/progressbar.types").MessengerActionTypeEnum}
 */
export const MESSENGER_ACTION_AMQP_TYPES = new Proxy(
  window.__RCMessengerActionTypeEnum__,
  {
    get(obj, prop) {
      return obj[prop];
    },
  }
);

/**
 * Queries
 */
export const PREFETCH_PAGINATION = {
  page: 1,
  perPage: 1000,
};
export const DEFAULT_SORT = { field: 'name', order: 'ASC' };
export const DEFAULT_PAGINATION = { page: 1, perPage: 1000 };
export const ID_SORT = {
  field: 'id',
  order: 'DESC',
};
export const NAME_SORT = {
  field: 'name',
  order: 'ASC',
};
export const VERSION_SORT = {
  field: 'version',
  order: 'ASC',
};

/**
 * Form values
 */

// TODO: Fetch from the backend
export const RESOURCE_SOURCES = ['cpu', 'memory', 'storage'];
export const CPU_RESOURCE_MIN_VALUE = 250;
export const CPU_RESOURCE_MAX_VALUE = 8000;
export const MEMORY_RESOURCE_MIN_VALUE = 512;
export const MEMORY_RESOURCE_MAX_VALUE = 8192;
export const STORAGE_RESOURCE_MIN_VALUE = 5000;
export const STORAGE_RESOURCE_MAX_VALUE = 100000;
export const ENABLE_NODE_RESOURCE_VALUES = false;
export const NODE_RESOURCE_VALUES = [1, 3, 5];

export const WORKER_DATA_TYPE = 'WorkerData';
export const SECURED_VALUE_IDENTIFIER = '<SECURED_VALUE>';
export const INTERNAL_GIT_TYPE_NAME = 'internal';
export const INTERNAL_GIT_TYPE_ID = '/api/git_types/1';
export const EXTERNAL_GIT_TYPE_ID = '/api/git_types/2';
export const EXTERNAL_GIT_TYPE_NAME = 'external';
export const HTTP_GIT_CREDENTIAL_TYPE_ID = '/api/credential_types/1';
export const HTTP_GIT_CREDENTIAL_TYPE_NAME = 'http';
export const SSH_GIT_CREDENTIAL_ID = '/api/credential_types/2';
export const SSH_GIT_CREDENTIAL_NAME = 'ssh';
export const DEFAULT_ENVIRONMENT_NAME = 'main';
export const ADMIN_FALLBACK_STORAGE_ID = 'adminFallback';
export const CLUSTER_REFERENCE_FIELD_DEPENDENCIES = ['provider'];
export const SHALLOW_CLUSTER_REFERENCE_FIELD_DEPENDENCIES = ['__provider'];
export const DEFAULT_CRYPTO_KEY_ENCODING_KEY = 'ED25519';
