/* eslint-disable */

import React from 'react';
import {
  ListContextProvider,
  MenuItemLink,
  ResourceContextProvider,
  useCreatePath,
  useGetList,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  styled,
  Box,
  Button,
  Stack,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import {
  CardList,
  Iconify,
  MercureSubscriberForList
} from '@rc/admin/components';
import { ROLES, STATE_IDS } from '@rc/admin/constants';
import { useIsTemplateResource } from '@rc/admin/hooks';
import { EnvironmentCardContent } from '@rc/admin/resources/environment/components';
import CustomPopover, {
  usePopover
} from '@rc/admin/theme/MinimalTheme/components/custom-popover';

/**
 *
 * @typedef {object} RelatedEnvironmentsProps
 * @property {string} resource
 *
 * @param {import('@mui/material').BoxProps & RelatedEnvironmentsProps} props
 * @returns
 */
export const RelatedEnvironments = props => {
  const { resource, sx, ...rest } = props;
  const t = useTranslate();
  const project = useRecordContext();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(ROLES.ADMIN);
  const createPath = useCreatePath();
  const isTemplate = useIsTemplateResource(resource);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
  const popover = usePopover();

  const environmentsCount = isTemplate
    ? project?.templateEnvironment?.length
    : project?.environment?.length;

  const {
    data: environments = [],
    isLoading,
    total
  } = useGetList(
    resource,
    {
      filter: isTemplate
        ? { templateProject: project?.id }
        : {
            project: project?.id,
            state: Object.values(STATE_IDS)
              .filter(id => (!isAdmin ? id != STATE_IDS.DELETED : true))
              .map(id => `/api/states/${id}`)
          }
    },
    { enabled: !!environmentsCount && !!permissions?.length }
  );

  const createTitle = t('resources.projects.action.create_environment');
  const createEnvironmentPath = {
    pathname: createPath({ resource, type: 'create' }),
    search: `project=${encodeURIComponent(project?.id)}`
  };

  const cloneableEnvironments = environments.filter(
    environment => environment.state !== STATE_IDS.DELETED
  );

  const empty = (
    <ResourceContextProvider value={resource}>
      <StyledBox
        className={RelatedEnvironmentClasses.empty}
        sx={theme => ({
          display: 'flex',
          justifyContent: 'flex-start',
          height: theme.spacing(10),
          width: '100%',
          ...(typeof sx === 'function' ? sx(theme) : sx)
        })}
        {...rest}
      >
        <Button
          component={Link}
          to={createEnvironmentPath}
          startIcon={<AddIcon />}
          size={'large'}
          variant='outlined'
          id='create-environment-button'
          project={project}
          sx={() => ({
            borderStyle: 'dashed',
            width: 'max(25%, 240px)',
            height: '100%',
            '&:hover': {
              borderStyle: 'unset'
            }
          })}
        >
          {createTitle}
        </Button>
      </StyledBox>
    </ResourceContextProvider>
  );

  if (project && !environmentsCount) {
    return empty;
  }

  return (
    <ResourceContextProvider value={resource}>
      <ListContextProvider
        value={{
          data: environments,
          resource,
          sort: { field: 'id', order: 'ASC' },
          page: 1,
          isLoading,
          total
        }}
      >
        <StyledBox {...rest} sx={sx}>
          <MercureSubscriberForList component={'span'} />

          <CardList
            empty={empty}
            enablePlaceholders
            placeholdersCount={project?.environment?.length || 4}
            sx={theme => ({ marginTop: theme.spacing(1) })}
            render={(record, _, isPlaceholder) => (
              <EnvironmentCardContent
                record={record}
                isPlaceholder={isPlaceholder}
              />
            )}
          >
            {!isLoading && (
              <Stack
                height='100%'
                justifyContent='center'
                alignItems='flex-start'
              >
                {isSmall ? (
                  <Button
                    LinkComponent={Link}
                    to={createEnvironmentPath}
                    onClick={e => {
                      if (!cloneableEnvironments.length) {
                        return;
                      }

                      e.preventDefault();
                      popover.onOpen(e);
                    }}
                    startIcon={<AddIcon />}
                  >
                    {createTitle}
                  </Button>
                ) : (
                  <Tooltip describeChild title={createTitle}>
                    <IconButton
                      LinkComponent={Link}
                      to={createEnvironmentPath}
                      onClick={e => {
                        if (!cloneableEnvironments.length) {
                          return;
                        }

                        e.preventDefault();
                        popover.onOpen(e);
                      }}
                      size='large'
                      sx={theme => ({
                        opacity: 0.5,
                        '&:hover': {
                          opacity: 1
                        },
                        '& svg': {
                          color:
                            theme.palette.mode === 'light'
                              ? theme.palette.primary.main
                              : theme.palette.primary.contrastText
                        }
                      })}
                    >
                      <Iconify icon='solar:add-circle-outline' width={36} />
                    </IconButton>
                  </Tooltip>
                )}
                {!!cloneableEnvironments?.length && (
                  <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow='right-top'
                  >
                    <MenuItemLink
                      onClick={e => {
                        e.stopPropagation();
                        popover.onClose();
                      }}
                      to={createEnvironmentPath}
                    >
                      <Iconify icon='solar:add-circle-outline' />
                      {t('action.create_new')}
                    </MenuItemLink>
                    {cloneableEnvironments.map(environment => (
                      <MenuItemLink
                        onClick={e => {
                          e.stopPropagation();
                          popover.onClose();
                        }}
                        to={{
                          pathname: `${createPath({
                            resource,
                            type: 'create'
                          })}/clone`,
                          search: `project=${encodeURIComponent(
                            project?.id
                          )}&sourceId=${encodeURIComponent(environment?.id)}`
                        }}
                      >
                        <Iconify icon='solar:copy-bold-duotone' />
                        {t('action.cloneWithParams', {
                          name: environment.name
                        })}
                      </MenuItemLink>
                    ))}
                  </CustomPopover>
                )}
              </Stack>
            )}
          </CardList>
        </StyledBox>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

const PREFIX = 'Environments';

export const RelatedEnvironmentClasses = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarSpacer: `${PREFIX}-toolbarSpacer`,
  hidden: `${PREFIX}-hidden`,
  accordion: `${PREFIX}-accordion`,
  actions: `${PREFIX}-actions`,
  loading: `${PREFIX}-loading`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  empty: `${PREFIX}-empty`
};

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),

  [`& .${RelatedEnvironmentClasses.toolbar}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 0,
    marginBottom: theme.spacing(-1),
    paddingTop: 0,
    backgroundColor: 'transparent',
    transition: 'opacity 0.3s ease-in-out'
  },

  [`& .${RelatedEnvironmentClasses.toolbarSpacer}`]: {
    display: 'none'
  },

  [`& .${RelatedEnvironmentClasses.hidden}`]: {
    opacity: 0
  },

  [`& .${RelatedEnvironmentClasses.actions}`]: {
    display: 'flex',
    columnGap: theme.spacing(1),
    justifyContent: 'flex-end'
  },

  [`& .${RelatedEnvironmentClasses.loading}`]: {
    placeSelf: 'center',
    padding: 15
  },

  [`& .${RelatedEnvironmentClasses.listItemIcon}`]: {
    minWidth: theme.spacing(4)
  }
}));

RelatedEnvironments.defaultProps = {
  sx: {}
};
