import { DATA_TRANSFER_TYPE_IDS } from '@rc/admin/constants';
import {
  useIsTemplateResource,
  useProjectResourceLimits,
  useResourceTypes
} from '@rc/admin/hooks';
import { useAxios } from '@rc/utils/axios';
import {
  CPU_RESOURCE_MIN_VALUE,
  DEFAULT_ENVIRONMENT_NAME,
  ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS,
  ENABLE_NODE_RESOURCE_VALUES,
  MEMORY_RESOURCE_MIN_VALUE,
  NODE_RESOURCE_VALUES,
  STORAGE_RESOURCE_MIN_VALUE
} from '@rc/admin/constants';
import { useCallback, useMemo, useRef } from 'react';
import {
  useCreate,
  useGetList,
  useGetOne,
  useNotify,
  useRedirect,
  useResourceContext
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useProjectType } from '../../project/hooks';
import { COPY_ENVIRONMENT_SOURCE } from '../constants';
import {
  useComponentResourceLimits,
  useEnvironmentResourceLimits,
  useValidateName
} from '../hooks';
import { isNumber } from 'lodash';
// import EnvironmentCreateDetailsTour from './CreateFeatureTour.js';

/**
 *
 * @param {object} props
 * @param {object} props.defaultValues
 * @param {string | undefined} props.projectId
 * @returns
 */
export const useEnvironmentCreate = (props = {}) => {
  const { defaultValues: defaultValuesProp, projectId: projectIdProp } = props;
  const resource = useResourceContext();
  const isTemplate = useIsTemplateResource();
  const { search } = useLocation();
  const redirect = useRedirect();
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate();

  const environmentComponentLastCopiedFrom = useRef('');

  const [{ loading: isImportLoading }, executeImport] = useAxios(
    { method: 'POST' },
    { manual: true }
  );

  const projectId =
    projectIdProp ||
    decodeURIComponent(new URLSearchParams(search).get('project'));

  const {
    data: project,
    isLoading: isProjectLoading,
    isError: isProjectError,
  } = useGetOne(
    isTemplate ? 'template_projects' : 'projects',
    { id: projectId },
    { enabled: !!projectId }
  );

  const { projectType, isLoading: isProjectTypeLoading } = useProjectType({
    project
  });

  const {
    data: siblingEnvironments,
    isLoading: isSiblingEnvironmentsLoading,
    isError: isSiblingEnvironmentsError
  } = useGetList(
    isTemplate ? 'template_environments' : 'environments',
    {
      filter: isTemplate
        ? { templateProject: projectId }
        : { project: projectId }
    },
    { enabled: !!projectId }
  );

  const { validate: validateName } = useValidateName({
    siblingEnvironments
  });

  const {
    componentResourceLimits,
    isLoading: isLoadingComponentResourceLimits
  } = useComponentResourceLimits({
    projectTypeVersion: project?.projectTypeVersion,
    componentVersion: project?.componentVersion
  });

  const {
    isLoading: isProjectResourceLimitsLoading,
    ...projectResourceLimits
  } = useProjectResourceLimits({ project });

  const {
    isLoading: isEnvironmentResourceLimitsLoading,
    ...environmentResourceLimits
  } = useEnvironmentResourceLimits({
    project
  });

  const { getResourceType, isLoading: isResourceTypesLoading } =
    useResourceTypes();

  const defaultValues = useMemo(() => {
    if (
      isResourceTypesLoading ||
      isProjectResourceLimitsLoading ||
      isEnvironmentResourceLimitsLoading
    ) {
      return {};
    }

    const name = !validateName(DEFAULT_ENVIRONMENT_NAME)
      ? DEFAULT_ENVIRONMENT_NAME
      : '';

    const environmentComponent = !project
      ? []
      : getDefaultEnvironmentComponents(
          project.componentVersion,
          componentResourceLimits,
          getResourceType
        );

    return {
      name,
      targetRefName: name,
      ...(isTemplate ? { templateProject: projectId } : { project: projectId }),
      isInitSampleData: false,
      isAllowHtaccessDevtoolsProtection: false,
      isAllowSiteProtection: false,
      isDisableRobots: true,
      envVar: [],
      environmentCustomDomain: [],
      environmentMagentoHostMapping: projectType?.codeName.includes('magento')
        ? [
            {
              hostname: 'default',
              mageRunType: null,
              mageRunCode: 'default'
            }
          ]
        : [],
      componentResourceLimits,
      environmentComponent,
      cpu: Math.max(
        getEnvironmentResourceTypeMin(
          'cpu',
          environmentResourceLimits.cpu,
          projectResourceLimits,
          environmentComponent
        ),
        CPU_RESOURCE_MIN_VALUE
      ),
      memory: Math.max(
        getEnvironmentResourceTypeMin(
          'memory',
          environmentResourceLimits.memory,
          projectResourceLimits,
          environmentComponent
        ),
        MEMORY_RESOURCE_MIN_VALUE
      ),
      storage: Math.max(
        getEnvironmentResourceTypeMin(
          'storage',
          environmentResourceLimits.storage,
          projectResourceLimits,
          environmentComponent
        ),
        STORAGE_RESOURCE_MIN_VALUE
      ),
      ...defaultValuesProp
    };
  }, [isResourceTypesLoading, isProjectResourceLimitsLoading, isEnvironmentResourceLimitsLoading, validateName, project, componentResourceLimits, getResourceType, isTemplate, projectId, projectType?.codeName, environmentResourceLimits.cpu, environmentResourceLimits.memory, environmentResourceLimits.storage, projectResourceLimits, defaultValuesProp]);

  const handleSubmit = useCallback(
    async data => {
      const { sourceId: copySourceId, types: copyTypes } =
        data[COPY_ENVIRONMENT_SOURCE] || {};
      delete data[COPY_ENVIRONMENT_SOURCE];

      try {
        await create(
          resource,
          { data },
          {
            onSuccess: async result => {
              let importFailed;
              if (!isTemplate && copySourceId) {
                try {
                  if (copyTypes.some(type => type === 'database')) {
                    const data = new window.FormData();
                    data.append(
                      'dataTransferSettings',
                      JSON.stringify({
                        type: DATA_TRANSFER_TYPE_IDS.COPY_ENVIRONMENT,
                        sourceEnvironment: copySourceId,
                        uploadProcess: 'import-database',
                        cleanup: true,
                        fixUrls: true,
                        minioSettings: {}
                      })
                    );

                    await executeImport({
                      url: `/api/environments/${result.originId}/import-database`,
                      data
                    });
                  }

                  if (copyTypes.some(type => type === 'media')) {
                    const data = new window.FormData();
                    data.append(
                      'dataTransferSettings',
                      JSON.stringify({
                        type: DATA_TRANSFER_TYPE_IDS.COPY_ENVIRONMENT,
                        sourceEnvironment: copySourceId,
                        uploadProcess: 'import-media',
                        cleanup: true,
                        fixUrls: true,
                        minioSettings: {}
                      })
                    );

                    await executeImport({
                      url: `/api/environments/${result.originId}/import-media`,
                      data
                    });
                  }
                } catch (error) {
                  importFailed = true;
                  notify(
                    'resources.environments.notification.created_but_imports_failed',
                    {
                      type: 'info'
                    }
                  );
                }
              }

              if (!importFailed) {
                notify('ra.notification.created', { type: 'success' });
              }
              redirect('edit', resource, result.id, result);
            }
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    [create, executeImport, notify, redirect, resource]
  );

  return {
    isLoading:
      isProjectLoading ||
      isProjectTypeLoading ||
      isLoadingComponentResourceLimits ||
      isResourceTypesLoading ||
      isProjectResourceLimitsLoading ||
      isEnvironmentResourceLimitsLoading ||
      isSiblingEnvironmentsLoading,
    isError: isProjectError || isSiblingEnvironmentsError,
    defaultValues,
    validateName,
    project,
    projectId,
    isCreateLoading,
    isImportLoading,
    isTemplate,
    handleSubmit,
    environmentComponentLastCopiedFrom,
    siblingEnvironments
  };
};

const getDefaultEnvironmentComponents = (
  componentVersion,
  componentResourceLimits,
  getResourceType
) => {
  return componentVersion.map(componentVersion => {
    const getComponentResourceTypeMin = name => {
      const resourceType = getResourceType(name);

      const componentResourceLimit = componentResourceLimits?.find(
        limit =>
          limit.componentVersion === componentVersion &&
          limit.resourceType === resourceType?.id
      );

      const { componentMinValue } = resourceType?.resourceTypeLimits || {};

      return (
        componentResourceLimit?.minValue ||
        (isNumber(componentMinValue)
          ? componentMinValue
          : Number.NEGATIVE_INFINITY)
      );
    };

    return {
      componentVersion,
      ...(ENABLE_NODE_RESOURCE_VALUES ? { node: NODE_RESOURCE_VALUES[0] } : {}),
      cpu: Math.max(getComponentResourceTypeMin('cpu'), CPU_RESOURCE_MIN_VALUE),
      memory: Math.max(
        getComponentResourceTypeMin('memory'),
        MEMORY_RESOURCE_MIN_VALUE
      ),
      storage: Math.max(
        getComponentResourceTypeMin('storage')
        // STORAGE_RESOURCE_MIN_VALUE
      )
    };
  });
};

const getEnvironmentResourceTypeMin = (
  name,
  limit,
  projectResourceLimits,
  environmentComponent
) => {
  const aggregatedEnvironmentComponentValue = environmentComponent.reduce(
    (result, { [name]: value }) => result + value,
    0
  );

  if (ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS) {
    return Math.max(aggregatedEnvironmentComponentValue);
  }

  return projectResourceLimits[name]?.free || 0;
};
